import Icon, { ICONS } from "components/common/Icons";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useIsMobile from "states/deviceState";
import { TYPO } from "styles/Typo";

const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.1746 19.5144C21.5449 20.1809 21.063 21 20.3005 21L3.69951 21C2.93703 21 2.45506 20.1809 2.82536 19.5144L11.1258 4.57348C11.5068 3.88767 12.4932 3.88767 12.8742 4.57348L21.1746 19.5144Z"
        fill="#FA4D56"
      />
      <path
        d="M10.6598 10.4915C10.5744 9.69479 11.1988 9 12 9C12.8012 9 13.4256 9.69479 13.3402 10.4915L12.8299 15.2543C12.7845 15.6784 12.4265 16 12 16C11.5735 16 11.2155 15.6784 11.1701 15.2543L10.6598 10.4915Z"
        fill="white"
      />
      <rect x="11" y="17" width="2" height="2" rx="1" fill="white" />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.6 11.8L6.45 9.65C6.26667 9.46667 6.03333 9.375 5.75 9.375C5.46667 9.375 5.23333 9.46667 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.1 14.1 8.33333 14.2 8.6 14.2C8.86667 14.2 9.1 14.1 9.3 13.9L14.95 8.25C15.1333 8.06667 15.225 7.83333 15.225 7.55C15.225 7.26667 15.1333 7.03333 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
        fill="#4589FF"
      />
    </svg>
  );
};

export function RebalanceSaveToast({
  message,
  isVisible,
  onClose,
  isSuccess = true,
  width,
  fromTop = false,
  buttonMessage,
  buttonHanlder,
}: any) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); //
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const isMobile = useIsMobile();
  const portalTarget = isClient ? document.getElementById("portal") : null;

  if (!portalTarget) return <></>;

  return createPortal(
    <div
      style={{
        position: "fixed",
        // bottom: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        color: "white",
        padding: "10px",
        borderRadius: "16px",
        zIndex: 1000,
        minWidth: width ? `${width}px` : "327px",
      }}
      className={`${
        isVisible
          ? fromTop
            ? isMobile
              ? "top-toast "
              : "top-desktop-toast"
            : "bottom-toast"
          : "hidden"
      } px-4 py-[10px] flex gap-3 items-center`}
    >
      <div className="flex gap-2 items-center grow">
        <div className="shrink-0">
          {" "}
          {/* <Icon name={ICONS.CHECK_FILL} height={24} width={24} fill="#4589FF" /> */}
          {isSuccess ? <CheckIcon /> : <ErrorIcon />}
        </div>

        <p className={`${TYPO.TYPO6_MD} text-gray10 whitespace-pre-line`}>
          {message}
        </p>
      </div>
      <div
        className={`cursor-pointer h-[32px] px-2 py-[6px] flex items-center justify-center bg-gray5 rounded-[8px] ${TYPO.TYPO8_MD} text-gray60 `}
        onClick={() => {
          if (buttonHanlder) {
            buttonHanlder();
          }
        }}
      >
        {buttonMessage}
      </div>
    </div>,
    portalTarget // portal 대상
  );
}

function CustomToastComponent({
  message,
  isVisible,
  onClose,
  isSuccess = true,
  width,
  fromTop = false,
  duration = 1000,
}: any) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration); // 1초 후 토스트 숨김
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const isMobile = useIsMobile();
  const portalTarget = isClient ? document.getElementById("portal") : null;

  if (!portalTarget) return <></>;

  return createPortal(
    <div
      style={{
        position: "fixed",
        // bottom: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        color: "white",
        padding: "10px",
        borderRadius: "16px",
        zIndex: 1000,
        minWidth: width ? `${width}px` : "220px",
      }}
      className={`${
        isVisible
          ? fromTop
            ? isMobile
              ? "top-toast "
              : "top-desktop-toast"
            : "bottom-toast"
          : "hidden"
      } px-4 py-[10px] flex gap-2 items-center`}
    >
      <div className="shrink-0">
        {" "}
        {/* <Icon name={ICONS.CHECK_FILL} height={24} width={24} fill="#4589FF" /> */}
        {isSuccess ? <CheckIcon /> : <ErrorIcon />}
      </div>

      <p className={`${TYPO.TYPO6_MD} text-gray10 whitespace-pre-line`}>
        {message}
      </p>
    </div>,
    portalTarget // portal 대상
  );
}

const CustomToast = React.memo(CustomToastComponent);

export default CustomToast;
