// 동적으로 들어갈 tailwind css class를 정의하는 파일



export const ICON_FILL: any = {
    'neutral-black': 'text-neutral-black',
    'neutral-white': 'text-neutral-white',
    'gray2': 'text-gray2',
    'gray5': 'text-gray5',
    'gray10': 'text-gray10',
    'gray15': 'text-gray15',
    'gray20': 'text-gray20',
    'gray30': 'text-gray30',
    'gray40': 'text-gray40',
    'gray50': 'text-gray50',
    'gray60': 'text-gray60',
    'gray70': 'text-gray70',
    'gray80': 'text-gray80',
    'gray90': 'text-gray90',

    'blue5': 'text-blue5',
    'blue10': 'text-blue10',
    'blue20': 'text-blue20',
    'blue30': 'text-blue30',
    'blue40': 'text-blue40',
    'blue50': 'text-blue50',
    'blue60': 'text-blue60',
    'blue70': 'text-blue70',
    'blue80': 'text-blue80',
    'blue90': 'text-blue90',

    'red5': 'text-red5',
    'red10': 'text-red10',
    'red20': 'text-red20',
    'red30': 'text-red30',
    'red40': 'text-red40',
    'red50': 'text-red50',
    'red60': 'text-red60',
    'red70': 'text-red70',
    'red80': 'text-red80',
    'red90': 'text-red90',


    'risk-green': 'text-risk-green',
    'risk-yellow': 'text-risk-yellow',
    'risk-orange': 'text-isk-orange',
    'risk-red': 'text-risk-red',

    'risk-bg-green': 'text-risk-bg-green',
    'risk-bg-yellow': 'text-risk-bg-yellow',
    'risk-bg-orange': 'text-risk-bg-orange',
    'risk-bg-red': 'text-risk-bg-red',
}

type TailwindCSSKeys =
    | 'blue-rgba'
    | 'gray-rgba'
    | 'light-gray-rgba'
    | 'mapBg'
    | 'chart1'
    | 'chart2'
    | 'chart3'
    | 'chart4'
    | 'chart5'
    | 'chart6'
    | 'chart7'
    | 'chart8'
    | 'chart9'
    | 'chart10'
    | 'neutral-black'
    | 'neutral-white'
    | 'gray2'
    | 'gray5'
    | 'gray10'
    | 'gray15'
    | 'gray20'
    | 'gray30'
    | 'gray40'
    | 'gray50'
    | 'gray60'
    | 'gray70'
    | 'gray80'
    | 'gray90'
    | 'blue5'
    | 'blue10'
    | 'blue20'
    | 'blue30'
    | 'blue40'
    | 'blue50'
    | 'blue60'
    | 'blue70'
    | 'blue80'
    | 'blue90'
    | 'red5'
    | 'red10'
    | 'red20'
    | 'red30'
    | 'red40'
    | 'red50'
    | 'red60'
    | 'red70'
    | 'red80'
    | 'red90'
    | 'risk-green'
    | 'risk-yellow'
    | 'risk-orange'
    | 'risk-red'
    | 'risk-bg-green'
    | 'risk-bg-yellow'
    | 'risk-bg-orange'
    | 'risk-bg-red';


export const TAILWIND_TO_CSS: Record<TailwindCSSKeys, string> = {
    "blue-rgba": "rgba(1, 152, 255, 0.05)",
    "gray-rgba": "rgba(17, 17, 17, 0.5);",
    "light-gray-rgba": "rgba(0, 0, 0, 0.2)",
    "mapBg": "#AFD5F0",
    "chart1": "#3366E6",
    "chart2": "#FF6633",
    "chart3": "#FF33FF",
    "chart4": "#FFFF99",
    "chart5": "#00B3E6",
    "chart6": "#E6B333",
    "chart7": "#FFB399",
    "chart8": "#999966",
    "chart9": "#99FF99",
    "chart10": "#B34D4D",
    'neutral-black': '#000',
    'neutral-white': '#FFF',
    // 'gray2': '#F7F8FA',
    // 'gray5': '#F2F4F8',
    // 'gray10': '#EBEEF2',
    // 'gray20': '#DDE1E6',
    // 'gray30': '#C1C7CD',
    // 'gray40': '#A2A9B0',
    // 'gray50': '#878D96',
    // 'gray60': '#697077',
    // 'gray70': '#4D5358',
    // 'gray80': '#343A3F',
    // 'gray90': '#21272A',
    'gray2': '#F6F7F9',
    'gray5': '#EFF2F5',
    'gray10': '#E9EDF1',
    'gray15': " #E3E8EE",
    'gray20': '#DAE1E9',
    'gray30': '#BDC7D1',
    'gray40': '#9FADBC',
    'gray50': '#8194A7',
    'gray60': '#647A90',
    'gray70': '#4F6272',
    'gray80': '#3B4754',
    'gray90': '#262E36',

    'blue5': '#EDF5FF',
    'blue10': '#D0E2FF',
    'blue20': '#A6C8FF',
    'blue30': '#78A9FF',
    'blue40': '#4589FF',
    'blue50': '#0F62FE',
    'blue60': '#0043CE',
    'blue70': '#002D9C',
    'blue80': '#001D6C',
    'blue90': '#001141',

    'red5': '#FFF1F1',
    'red10': '#FFD7D9',
    'red20': '#FFB3B8',
    'red30': '#FF8389',
    'red40': '#FA4D56',
    'red50': '#DA1E28',
    'red60': '#A2191F',
    'red70': '#750E13',
    'red80': '#520408',
    'red90': '#2D0709',


    'risk-green': '#34BB7A',
    'risk-yellow': '#F99F01',
    'risk-orange': '#FF4D00',
    'risk-red': '#DF1525',

    'risk-bg-green': '#E6F5F0',
    'risk-bg-yellow': '#FEF5E6',
    'risk-bg-orange': '#FFE4D9',
    'risk-bg-red': '#FFECEF',
}