import { useRouter } from "next/router";
import {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useEffect,
} from "react";

type PortfolioStateType = {
  isAddOpen: boolean;
  isDetailOpen: boolean;
  isEditOpen: boolean;
  isAssetAddOpen: boolean;
  isNextStep: boolean;
  portfolio: any;
  baseCurrency: string;

  toastVisible?: boolean;
  toastMessage?: string;
  toastType?: "success" | "error" | undefined;
  toastWidth?: number;
};

type PortfolioActionType =
  | { type: "SET_ADD_OPEN"; payload: boolean }
  | { type: "SET_DETAIL_OPEN"; payload: boolean }
  | { type: "SET_EDIT_OPEN"; payload: boolean }
  | { type: "SET_ASSETADD_OPEN"; payload: boolean }
  | { type: "SET_NEXTSTEP"; payload: boolean }
  | { type: "SET_PORTFOLIO"; payload: any }
  | { type: "RESET" }
  | { type: "SET_BASECURRENCY"; payload: string }
  | { type: "TOAST_OPEN"; payload: any }
  | { type: "TOAST_CLOSE" };

const initialPortfolioState: PortfolioStateType = {
  isAddOpen: false,
  isDetailOpen: false,
  isEditOpen: false,
  isAssetAddOpen: false,
  isNextStep: false,
  portfolio: [],
  baseCurrency: "KRW",

  toastVisible: false,
  toastMessage: "",
  toastType: undefined,
  toastWidth: 250,
};

const PortfolioStateContext = createContext<PortfolioStateType | undefined>(
  undefined
);
const PortfolioDispatchContext = createContext<
  React.Dispatch<PortfolioActionType> | undefined
>(undefined);

type PortfolioProviderProps = {
  children?: ReactNode;
};

export function PortfolioProvider({ children }: PortfolioProviderProps) {
  const router = useRouter();

  const [state, dispatch] = useReducer(
    (state: PortfolioStateType, action: PortfolioActionType) => {
      switch (action.type) {
        case "TOAST_OPEN":
          return {
            ...state,
            toastVisible: true,
            toastMessage: action.payload?.message,
            toastType: action.payload?.type,
            toastWidth: action.payload?.width || 250,
          };

        case "TOAST_CLOSE":
          return {
            ...state,
            toastVisible: false,
            toastMessage: "",
            toastType: undefined,
          };
        case "SET_ADD_OPEN":
          return {
            ...state,
            isAddOpen: action.payload,
          };
        case "SET_DETAIL_OPEN":
          return {
            ...state,
            isDetailOpen: action.payload,
          };
        case "SET_NEXTSTEP":
          return {
            ...state,
            isNextStep: action.payload,
          };
        case "SET_ASSETADD_OPEN":
          return {
            ...state,
            isAssetAddOpen: action.payload,
          };
        case "SET_EDIT_OPEN":
          return {
            ...state,
            isEditOpen: action.payload,
          };
        case "SET_PORTFOLIO":
          return {
            ...state,
            portfolio: action.payload,
          };

        case "RESET":
          return {
            ...initialPortfolioState,
          };
        case "SET_BASECURRENCY":
          return {
            ...state,
            baseCurrency: action.payload,
          };
        default:
          return state;
      }
    },
    initialPortfolioState
  );
  useEffect(() => {
    if (router?.locale === "en")
      dispatch({ type: "SET_BASECURRENCY", payload: "USD" });
  }, []);

  return (
    <PortfolioStateContext.Provider value={state}>
      <PortfolioDispatchContext.Provider value={dispatch}>
        {children}
      </PortfolioDispatchContext.Provider>
    </PortfolioStateContext.Provider>
  );
}

export function usePortfolioState() {
  const state = useContext(PortfolioStateContext);
  if (!state) throw new Error("Cannot find PortfolioStateProvider");
  return state;
}

export function usePortfolioDispatch() {
  const dispatch = useContext(PortfolioDispatchContext);
  if (!dispatch) throw new Error("Cannot find PortfolioDispatchProvider");
  return dispatch;
}
