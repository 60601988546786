import {
  useInterestToastDispatch,
  useInterestToastState,
} from "contexts/InterestContext";
import CustomToast from "./CustomToast";
import { useRouter } from "next/router";
import {
  usePortfolioDispatch,
  usePortfolioState,
} from "contexts/PortfolioContext";

//관심 등록 관련 토스트
function GlobalToast() {
  const router = useRouter();
  const dispatch = useInterestToastDispatch();
  const state = useInterestToastState();
  return (
    <div>
      <CustomToast
        message={
          router.locale == "ko"
            ? state.existInterest
              ? `관심 종목 취소에 실패했어요.\n 새로고침 후 다시 시도해주세요.`
              : `관심 종목 추가에 실패했어요.\n 새로고침 후 다시 시도해주세요.`
            : "Failed to add to watchlist.\n Please refresh to try again."
        }
        isVisible={state.interestErrorToastVisible}
        onClose={() => {
          // setInterestErrorToastVisible(false);
          dispatch({
            type: "SET_INTEREST_ERROR_TOAST_VISIBLE",
            payload: {
              ...state,
              toast: false,
            },
          });
        }}
        width={router.locale == "ko" ? 260 : 255}
        isSuccess={false}
      />
      <CustomToast
        message={
          router.locale == "ko"
            ? state.existInterest
              ? "관심 자산으로 추가됐어요"
              : "관심 자산이 삭제됐어요"
            : state.existInterest
            ? "Successfully added"
            : "Successfully deleted"
        }
        isVisible={
          // true
          state.interestToastVisible && !state.interestErrorToastVisible
        }
        onClose={() => {
          // setInterestToastVisible(false);
          dispatch({
            type: "SET_INTEREST_TOAST_VISIBLE",
            payload: {
              ...state,
              toast: false,
            },
          });
        }}
        width={
          state.existInterest
            ? router.locale === "ko"
              ? 210
              : 205
            : router.locale === "ko"
            ? 205
            : 210
        }
      />
    </div>
  );
}

export function GlobalPortfolioToast() {
  const dispatch = usePortfolioDispatch();
  const state = usePortfolioState();
  return (
    <div>
      <CustomToast
        message={state.toastMessage}
        isVisible={state.toastVisible}
        onClose={() => {
          dispatch({
            type: "TOAST_CLOSE",
          });
        }}
        width={state?.toastWidth}
        isSuccess={state.toastType === "success"}
        duration={2000}
      />
    </div>
  );
}

export default GlobalToast;
