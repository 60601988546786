import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  ReactNode,
} from "react";

export type State = {
  fromAddPage: boolean;
};

export type Action = { type: "FROM_ASSET_ADD"; payload: boolean };

type PortfolioDetailStateType = {
  fromAddPage: boolean;
};
type PortfolioDetailDispatchType = {
  dispatch: Dispatch<Action>;
};

type PortfolioDetailProviderProps = {
  children: ReactNode;
};

export const initialState = {
  fromAddPage: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FROM_ASSET_ADD":
      return { ...state, fromAddPage: action.payload };
    default:
      return state;
  }
};

const PortfolioDetailStateContext = createContext<
  PortfolioDetailStateType | undefined
>(undefined);

const PortfolioDetailDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined);

export const usePortfolioDetailState = () => {
  const context = useContext(PortfolioDetailStateContext);
  if (!context) {
    throw new Error(
      "usePortfolioDetail must be used within a PortfolioDetailProvider"
    );
  }
  return context;
};
export function usePortfolioDetailDispatch() {
  const dispatch = useContext(PortfolioDetailDispatchContext);
  if (!dispatch) throw new Error("Cannot find PortfolioDetailDispatchContext");
  return dispatch;
}

// PortfolioDetailProvider 컴포넌트
export const PortfolioDetailProvider = ({
  children,
}: PortfolioDetailProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PortfolioDetailStateContext.Provider value={state}>
      <PortfolioDetailDispatchContext.Provider value={dispatch}>
        {children}
      </PortfolioDetailDispatchContext.Provider>
    </PortfolioDetailStateContext.Provider>
  );
};
