// notificationsContext.tsx
import { useSession } from "next-auth/react";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useReducer,
} from "react";

type NotificationType = {
  newNotification: boolean;
};
export type ActionType = {
  type: "SET_NEW_NOTIFICATION";
  payload: boolean;
};

const NotificationsContext = createContext<NotificationType | undefined>(
  undefined
);
const NotificationsDispatchContext = createContext<
  React.Dispatch<ActionType> | undefined
>(undefined);

const initialNotifications: NotificationType = {
  newNotification: false,
};

export const NotificationsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [newNotification, dispatch] = useReducer(
    (state: NotificationType, action: ActionType) => {
      switch (action.type) {
        case "SET_NEW_NOTIFICATION":
          return { ...state, newNotification: action.payload };
        default:
          return state;
      }
    },
    initialNotifications
  );

  const { data: session }: any = useSession();

  const fetchData = async () => {
    try {
      const res = await fetch(
        session
          ? `/api/auth/alarmhistory?email=${session?.user?.email}&lastAlarmData=true`
          : "",
        { headers: { Accept: "application/json" } }
      );
      const alarmHistoryData = await res.json();
      let lastAlarmData;
      if (alarmHistoryData.length > 0) {
        lastAlarmData = alarmHistoryData[0];
      }
      // 로컬스토리지의 lastCheckTime과 현재 최신 CREATE_DATE를 가져와서 비교
      const lastCheckTime = localStorage.getItem("lastCheckTime");

      if (lastCheckTime && lastAlarmData) {
        const lastCheckTimeDate = new Date(lastCheckTime).toISOString();
        const lastAlarmDataDate = new Date(
          lastAlarmData.CREATE_DATE
        ).toISOString();

        if (lastAlarmDataDate > lastCheckTimeDate) {
          dispatch({ type: "SET_NEW_NOTIFICATION", payload: true });
        }
      } else {
        dispatch({ type: "SET_NEW_NOTIFICATION", payload: false });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (session) {
      fetchData();
      if (localStorage.getItem("lastCheckTime") === null) {
        localStorage.setItem("lastCheckTime", new Date().toISOString());
      }
    }
  }, [session]);

  return (
    <NotificationsContext.Provider value={newNotification}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const state = useContext(NotificationsContext);
  if (!state) throw new Error("Cannot find NotificationsProvider");
  return state;
}

export function useNotificationsDispatch() {
  const dispatch = useContext(NotificationsDispatchContext);
  if (!dispatch) throw new Error("Cannot find NotificationsProvider");
  return dispatch;
}
