import { GlobalProvider } from "contexts/SearchContext";
import { SignUpProvider } from "contexts/SignUpContext";
import { PortfolioProvider } from "contexts/PortfolioContext";
import "styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import type { AppContext, AppProps } from "next/app";
import Image from "next/image";
import { SessionProvider, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticPropsContext } from "next";
import "react-tooltip/dist/react-tooltip.css";
import UAParser from "ua-parser-js";
import { Tooltip } from "react-tooltip";
import Layout from "components/layouts/Layout";
import { NextPage } from "next";
import { useEffect } from "react";
import { PortfolioDetailProvider } from "contexts/PortfolioBackContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { AOSInit } from "utils/aos";
import Script from "next/script";
import Head from "next/head";
import { NotificationsProvider } from "contexts/NotificationContext";
import { InterestToastProvider } from "contexts/InterestContext";
import GlobalToast, {
  GlobalPortfolioToast,
} from "components/atoms/GlobalToast";
import GlobalRiskAlarmNotiModal from "components/atoms/GlobalAlarmModal";
import { SortProvider } from "contexts/SortContext";
import * as Sentry from "@sentry/nextjs";

const queryClient = new QueryClient();

function SentryUserManager() {
  const { data: session } = useSession();

  useEffect(() => {
    if (session && session.user) {
      Sentry.setUser({
        id: session.user.email as string,
        username: session.user.name as string,
      });
      // Sentry.captureException("user login");
    } else {
      Sentry.setUser(null);
    }
  }, [session]);
  return null;
}

function App({ Component, pageProps }: AppProps & { Component: NextPage }) {
  const router = useRouter();
  useEffect(() => {
    if (
      "scrollRestoration" in history &&
      history.scrollRestoration !== "manual"
    ) {
      history.scrollRestoration = "manual";
    }
  }, []);
  const { hasMetaData } = pageProps;
  const flag = hasMetaData ? hasMetaData : false;
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <GlobalProvider>
          <NotificationsProvider>
            <PortfolioDetailProvider>
              <PortfolioProvider>
                <SignUpProvider>
                  <InterestToastProvider>
                    <SortProvider>
                      <Layout {...pageProps}>
                        <AOSInit />
                        <GlobalToast />
                        <GlobalPortfolioToast />
                        <GlobalRiskAlarmNotiModal />
                        {!flag && (
                          <Head>
                            <link
                              rel="Icon"
                              href="/images/favicon.png"
                              type="image/x-icon"
                            />
                            <title>Riskweather</title>
                            <meta
                              property="og:image"
                              content={`https://riskweather.io/images/Thumb.png`}
                            />
                            <meta
                              property="og:description"
                              content={
                                router.locale === "ko"
                                  ? "투자 리스크 확인부터 관리까지, 모든 판단을 리스크웨더에서"
                                  : "Invest with Confidence, We've Got the Risks Covered"
                              }
                            />
                            <meta
                              name="description"
                              content={
                                "투자 리스크 확인부터 관리까지, 모든 판단을 리스크웨더에서 "
                              }
                            />
                            <meta
                              property="og:title"
                              content="리스크웨더 - 개인투자자를 보호합니다."
                            />
                            <meta
                              name="twitter:title"
                              content="리스크웨더 - 개인투자자를 보호합니다."
                            />
                            <meta
                              name="twitter:description"
                              content={
                                "투자 리스크 확인부터 관리까지, 모든 판단을 리스크웨더에서 "
                              }
                            />
                            <meta
                              name="twitter:image"
                              content={`https://riskweather.io/images/Thumb.png`}
                            />
                            <meta name="twitter:card" content={`summary`} />
                          </Head>
                        )}

                        <Component {...pageProps} />
                        <SentryUserManager />
                        <ToastContainer />
                        <Tooltip
                          id="riskLevel"
                          style={{
                            zIndex: 289,
                            fontSize: "small",
                            fontWeight: "bold",
                          }}
                        />
                        <Tooltip
                          id="riskIndexExplain"
                          style={{
                            zIndex: 289,
                            fontSize: "small",
                            fontWeight: "bold",
                            backgroundColor: "transparent",
                          }}
                        >
                          <Image
                            src={
                              router.locale == "ko"
                                ? "/images/explain/RiskIndexExplain.svg"
                                : "/images/explain/RiskIndexExplainEng.svg"
                            }
                            alt=""
                            width={350}
                            height={150}
                          />
                        </Tooltip>
                        <Tooltip
                          id="traitExplain"
                          style={{
                            zIndex: 289,
                            fontSize: "small",
                            fontWeight: "bold",
                            backgroundColor: "transparent",
                          }}
                        >
                          <Image
                            src={
                              router.locale == "ko"
                                ? "/images/traits/explanationKr.svg"
                                : "/images/traits/explanation.svg"
                            }
                            alt=""
                            width={350}
                            height={150}
                          />
                        </Tooltip>
                        <div id="portal"></div>
                      </Layout>
                    </SortProvider>
                  </InterestToastProvider>
                </SignUpProvider>
              </PortfolioProvider>
            </PortfolioDetailProvider>
          </NotificationsProvider>
        </GlobalProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

const AppWithI18n = appWithTranslation(App);

const AppWithAuth = (props: AppProps) => (
  <>
    <SessionProvider session={props.pageProps.session}>
      <AppWithI18n {...props} />
    </SessionProvider>
  </>
);

AppWithAuth.getInitialProps = async ({ ctx }: AppContext) => {
  const userAgent: string | undefined = ctx.req
    ? ctx.req.headers["user-agent"]
    : navigator.userAgent;

  const parser = new UAParser();
  let isMobile = false;

  if (userAgent) {
    const result = parser.setUA(userAgent).getResult();
    isMobile = result.device && result.device.type === "mobile";
  }
  // const result = parser.setUA(userAgent).getResult();
  // const isMobile = result.device && result.device.type === 'mobile';
  // const isMobi = userAgent?.indexOf("Mobi") !== -1;
  // const isMobi = Boolean(userAgent?.match(
  //   /Android|BlackBerry|iPhone|Galaxy|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
  // ) );
  // const width = typeof window !== 'undefined' ? window.innerWidth : screen.width;
  // const isMobileWidth = width !== null && width < 1180;
  // const isMobile = isMobi ;

  return { pageProps: { isMobile } };
};

export default AppWithAuth;

export async function getStaticProps(
  ctx: GetStaticPropsContext,
  { locale = "ko" }: GetStaticPropsContext
) {
  return {
    props: { ...(await serverSideTranslations(locale as string, ["common"])) },
  };
}
