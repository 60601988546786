import { ReactNode } from "react";
import useIsMobile from "states/deviceState";

interface ModalProps {
  children: ReactNode;
  isOpen?: boolean;
  modalRef: React.LegacyRef<HTMLDivElement> | undefined;
  isDesktop?: boolean;
  containerClass?: string;
}

const BottomModal = ({
  children,
  modalRef,
  isOpen,
  containerClass,
}: ModalProps) => {
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;

  const modalClasses = `absolute bottom-0  left-[50%] translate-x-[-50%] ${
    isDesktop ? "max-w-[800px] w-full" : "w-full"
  } px-6 pt-6 pb-10 bg-white rounded-tl-[20px] rounded-tr-[20px] flex-col justify-start items-start inline-flex transition-all ${
    containerClass ? containerClass : ""
  }  ${isOpen ? "modal-entering" : ""}`;
  return (
    <div
      className={`z-[500] fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div ref={modalRef} className={modalClasses}>
        {children}
      </div>
    </div>
  );
};

export default BottomModal;
