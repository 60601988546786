import { RefObject, useEffect, useRef } from "react";

const useModalClickOutside = (setModal: (value: boolean) => void, notClickable = false) => {

    const modalRef = useRef<HTMLDivElement>(null);

    // 모달 외부 클릭을 감지하는 이벤트 핸들러
    const handleClickOutside = (event: MouseEvent) => {
        if (notClickable) return;
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setModal(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return modalRef as React.LegacyRef<HTMLImageElement | HTMLDivElement> | undefined;
}

export default useModalClickOutside;