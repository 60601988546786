export const TYPO = {
    TYPO1_RG: "  text-3xl font-normal leading-[38px]",
    TYPO2_RG: ' text-[26px] font-normal leading-[32px]',
    TYPO3_RG: ' text-2xl font-normal leading-[32px]',
    TYPO4_RG: ' text-xl font-normal leading-7',
    TYPO5_RG: ' text-lg font-normal leading-7',
    TYPO5_1_RG: ' text-[17px] font-normal leading-[26px]',
    TYPO6_RG: ' text-base font-normal leading-relaxed',
    TYPO7_RG: ' text-[15px] font-normal leading-normal',
    TYPO7_1_RG: ' text-[14px] font-normal leading-[22px]',
    TYPO8_RG: ' text-[13px] font-normal leading-[20px]',
    TYPO9_RG: ' text-xs font-normal leading-[18px]',
    TYPO10_RG: ' text-[10px] font-normal leading-[15px]',


    TYPO1_MD: ' text-3xl font-medium leading-[38px]',
    TYPO2_MD: ' text-[26px] font-medium leading-[32px]',
    TYPO3_MD: ' text-2xl font-medium leading-[32px]',
    TYPO4_MD: ' text-xl font-medium leading-7',
    TYPO5_MD: ' text-lg font-medium leading-7',
    TYPO5_1_MD: ' text-[17px] font-medium leading-[26px]',
    TYPO6_MD: "  text-base font-medium leading-relaxed",
    TYPO7_MD: ' text-[15px] font-medium leading-normal',
    TYPO7_1_MD: ' text-[14px] font-medium leading-[22px]',
    TYPO8_MD: ' text-[13px] font-medium leading-[20px]',
    TYPO9_MD: ' text-xs font-medium leading-[18px]',
    TYPO10_MD: ' text-[10px] font-medium leading-[15px]',


    TYPO1_SB: ' text-3xl font-semibold leading-[38px]',
    TYPO2_SB: ' text-[26px] font-semibold leading-[32px]',
    TYPO3_SB: ' text-2xl font-semibold leading-[32px]',
    TYPO4_SB: ' text-xl font-semibold leading-7',
    TYPO5_SB: ' text-lg font-semibold leading-7',
    TYPO5_1_SB: ' text-[17px] font-semibold leading-[26px]',
    TYPO6_SB: ' text-base font-semibold leading-relaxed',
    TYPO7_SB: ' text-[15px] font-semibold leading-normal',
    TYPO7_1_SB: ' text-[14px] font-semibold leading-[22px]',
    TYPO8_SB: ' text-[13px] font-semibold leading-[20px]',
    TYPO9_SB: ' text-xs font-semibold leading-[18px]',
    TYPO10_SB: ' text-[10px] font-semibold leading-[15px]',


    TYPO1_B: ' text-3xl font-bold leading-[38px]',
    TYPO2_B: ' text-[26px] font-bold leading-[32px]',
    TYPO3_B: ' text-2xl font-bold leading-[32px]',
    TYPO4_B: ' text-xl font-bold leading-7',
    TYPO5_B: ' text-lg font-bold leading-7',
    TYPO5_1_B: ' text-[17px] font-bold leading-[26px]',
    TYPO6_B: ' text-base font-bold leading-relaxed',
    TYPO7_B: ' text-[15px] font-bold leading-normal',
    TYPO7_1_B: ' text-[14px] font-bold leading-[22px]',
    TYPO8_B: ' text-[13px] font-bold leading-[20px]',
    TYPO9_B: ' text-xs font-bold leading-[18px]',
    TYPO10_B: ' text-[10px] font-bold leading-[15px]',

    MENU_ACTIVE: 'text-zinc-800 text-[11px] font-medium leading-[14px]',
    MENU_INACTIVE: 'text-neutral-300 text-[11px] font-medium leading-[14px]'

}