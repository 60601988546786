// states/deviceState.ts
import { atom, useRecoilValue } from 'recoil';

export const isMobileState = atom({
    key: 'isMobileState', // 고유한 키
    default: false, // 기본값
});

const useIsMobile = () => {
    const isMobile = useRecoilValue(isMobileState);
    return isMobile;
}

export default useIsMobile;