// notificationsContext.tsx
import React, { createContext, useContext, ReactNode, useReducer } from "react";

type SortStateType = {
  RISK_SORT: 0 | 1 | 2 | 3;
  WATCHLIST_SORT: 0 | 1 | 2 | 3;
  INDEX_SELECTED: 0 | 1 | 2 | 3 | 4;
};

export type ActionType =
  | {
      type: "SET_RISK_SORT";
      payload: {
        sort: 0 | 1 | 2 | 3;
      };
    }
  | {
      type: "SET_WATCHLIST_SORT";
      payload: {
        sort: 0 | 1 | 2 | 3;
      };
    }
  | {
      type: "SET_INDEX_SELECTED";
      payload: {
        index: 0 | 1 | 2 | 3 | 4;
      };
    };

const SortStateContext = createContext<SortStateType | undefined>(undefined);
const SortDispatchContext = createContext<
  React.Dispatch<ActionType> | undefined
>(undefined);

const initialState: SortStateType = {
  RISK_SORT: 0,
  WATCHLIST_SORT: 0,
  INDEX_SELECTED: 0,
};

export const SortProvider = ({ children }: { children: ReactNode }) => {
  const [value, dispatch] = useReducer(
    (state: SortStateType, action: ActionType) => {
      switch (action.type) {
        case "SET_RISK_SORT":
          return {
            ...state,
            RISK_SORT: action.payload.sort,
          };
        case "SET_WATCHLIST_SORT":
          return {
            ...state,
            WATCHLIST_SORT: action.payload.sort,
          };
        case "SET_INDEX_SELECTED":
          return {
            ...state,
            INDEX_SELECTED: action.payload.index,
          };
        default:
          return state;
      }
    },
    initialState
  );

  return (
    <SortStateContext.Provider value={value}>
      <SortDispatchContext.Provider value={dispatch}>
        {children}
      </SortDispatchContext.Provider>
    </SortStateContext.Provider>
  );
};

export function useSortState() {
  const state = useContext(SortStateContext);
  if (!state) throw new Error("Cannot find SortProvider");
  return state;
}

export function useSortDispatch() {
  const dispatch = useContext(SortDispatchContext);
  if (!dispatch) throw new Error("Cannot find SortProvider");
  return dispatch;
}
