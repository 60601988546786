import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { CONTACTS, MENUS } from "data/footer";
import useHandleWeatherImageError from "utils/useHandleWeatherImageError";
import { TYPO } from "styles/Typo";

const Footer = () => {
  const router = useRouter();
  const handleImageError = useHandleWeatherImageError();

  return (
    <footer className="px-[30px]  pb-[100px] pl-6 w-800:pl-0 w-800:pr-[360px] min-w-[360px] flex justify-start  bg-gray-100 max-w-[800px]">
      <div className="flex flex-col gap-5 text-gray60">
        <Image
          onError={handleImageError}
          unoptimized
          quality={100}
          width={64}
          height={36}
          src={`/images/icons/footer/gne.svg`}
          alt="logo"
          // className=" mb-5"
        />
        <h1 className={`${TYPO.TYPO8_SB} text-gray60`}>
          {router.locale == "ko" ? "(주)지엔이테크홀딩스" : "GNE Tech Holdings"}
        </h1>
        <ul className="">
          {CONTACTS.map(({ id, title, koreanTitle, content }) => (
            <li key={id} className="flex items-center gap-2 mb-2.5 text-xs">
              <p className={`${TYPO.TYPO8_MD} w-[72px]`}>
                {router.locale == "ko" ? koreanTitle : title}
              </p>
              <p className={`${TYPO.TYPO8_RG}`}>{content}</p>
            </li>
          ))}
          <li className="flex items-center gap-2 mb-2.5 text-xs">
            <p className={`${TYPO.TYPO8_MD} w-[72px]`}>
              {router.locale === "ko" ? "데이터 제공" : "Powered by"}
            </p>
            <p className={`${TYPO.TYPO8_RG}`}>{">koscom"}</p>

            <Image
              quality={100}
              unoptimized
              src="/images/icons/footer/yahoo.svg"
              width={45}
              height={15}
              alt="yahoo"
            />
            <Image
              quality={100}
              unoptimized
              src="/images/icons/footer/coinmarketcap.svg"
              width={93}
              height={18}
              alt="yahoo"
            />
          </li>
          <li className="flex items-center gap-2 mb-2.5 text-xs">
            <p className={`${TYPO.TYPO8_MD} w-[72px]`}>
              {router.locale === "ko" ? "개인정보보호 책임자" : "CPO"}
            </p>
            <p className={`${TYPO.TYPO8_RG}`}>
              {router.locale === "ko" ? "이재웅" : "Jay Lee"}
            </p>
          </li>
        </ul>
        <p
          className={`${TYPO.TYPO8_SB} cursor-pointer hover:underline`}
          onClick={() => {
            router.push("/policy");
          }}
        >
          {router.locale === "ko" ? "개인정보처리방침" : "Privacy Policy"}
        </p>

        {/* <ul className="flex gap-4 text-gray-600 font-medium text-sm ">
          {MENUS.map(({ id, title, koreanTitle, path }) => (
            <li key={id} className={"flex items-center text-[10px]"}>
              <Link href={path}>
                {router.locale == "ko" ? koreanTitle : title}
              </Link>
              {title != "Privacy policy" && (
                <div className="h-3 w-px border border-solid border-gray-200 ml-3" />
              )}
            </li>
          ))}
        </ul> */}
      </div>
    </footer>
  );
};

export default Footer;
