import { MouseEvent } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import Head from "next/head";
import { useNotifications } from "contexts/NotificationContext";
import Icon, { ICONS } from "components/common/Icons";

const TopBar = () => {
  const { newNotification } = useNotifications();
  const router = useRouter();

  const handleMainPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    sessionStorage.removeItem(`main-scrollPosition`);
    sessionStorage.removeItem(`search-scrollPosition`);
    window.location.href = `/${router.locale}`;
  };

  if (
    router.pathname !== "/" &&
    router.pathname !== "/login" &&
    router.pathname !== "/portfolio" &&
    router.pathname !== "/profile" &&
    router.pathname !== "/watchlist" &&
    router.pathname !== "/signup" &&
    router.pathname !== "/login"
  ) {
    return <></>;
  }

  return (
    <header className="fixed top-0 w-full z-30 bg-white h-16 border-b border-gray-100 py-[14px] px-5 ">
      {/* <Head>
        <link rel="Icon" href="/images/favicon.png" type="image/x-icon" />
        <title>Riskweather</title>
        <meta
          property="og:image"
          content="https://riskweather.io/images/favicon.png"
        />
        <meta
          property="og:description"
          content={
            router.locale === "ko"
              ? "투자 리스크의 기준 - 리스크웨더"
              : "Invest with Confidence, We've Got the Risks Covered"
          }
        />
        <meta name="description" content={"투자 리스크의 기준 - 리스크웨더"} />

        <meta property="og:title" content="Riskweather" />
      </Head> */}

      <div className="h-full mx-auto flex items-center justify-between w-full">
        {router.pathname !== "/signup" && (
          <Link href="/" onClick={handleMainPage}>
            <Image
              width={30}
              height={30}
              unoptimized
              src={`/images/header/headerLogo.svg`}
              alt="logo"
              className="cursor-pointer w-auto h-auto"
            />
          </Link>
        )}

        {router.pathname !== "/login" &&
          router.pathname !== "/signup" &&
          !router.pathname.startsWith("/b2b") && (
            <section className="flex justify-center items-center gap-4">
              {/* <Image
              src={"/images/header/search.svg"}
              alt=""
              className="w-auto h-auto cursor-pointer"
              width={30}
              height={30}
              onClick={() => {
                router.push("/search-list");
              }}
            /> */}
              {/* <Image
              src={"/images/header/noti.svg"}
              alt=""
              className="w-auto h-auto cursor-pointer"
              width={30}
              height={30}
              onClick={() => {
                router.push("/alarm");
              }}
            /> */}

              <div
                onClick={() => {
                  router.push("/alarm");
                }}
                className="cursor-pointer"
              >
                <Icon
                  name={newNotification ? ICONS.NOTI_ON : ICONS.NOTI_OFF}
                  height={30}
                  width={30}
                  fill="gray40"
                />
              </div>
            </section>
          )}
      </div>
    </header>
  );
};

export default TopBar;
