import { ReactNode, useEffect } from "react";
import TopBar from "components/layouts/w/TopBar";
import MenuBar from "components/layouts/w/MenuBar";
import Footer from "components/layouts/w/Footer";
import MobileTopBar from "components/layouts/m/TopBar";
import MobileFooter from "components/layouts/m/MenuBar";
import { useRouter } from "next/router";
import NotiFooter from "./m/Footer/noti";
import { useRecoilState } from "recoil";
import { isMobileState } from "states/deviceState";
import B2bFooter from "./w/Footer/b2b";

interface Props {
  isMobile: boolean;
  children?: ReactNode;
}

const Layout = ({ isMobile, children }: Props) => {
  const [, setIsMobile] = useRecoilState(isMobileState);

  useEffect(() => {
    setIsMobile(isMobile);
  }, []);

  const router = useRouter();

  const isMainPage = router.pathname === "/";
  const isDetailPage = router.pathname.startsWith("/detail/");
  const isSignupPage = router.pathname.startsWith("/signup");
  const isSearchPage = router.pathname.startsWith("/search");
  const isSearchListPage = router.pathname.startsWith("/search-list");
  const isHighRiskPage = router.pathname.startsWith("/high-risk");
  const isAssetPage = router.pathname.startsWith("/asset");
  const isRealtimeRiskPage = router.pathname.startsWith("/realtime-risk");
  const isCountryPage = router.pathname.startsWith("/country-risk");

  const isPortfolioPage = router.pathname.startsWith("/portfolio");
  const isPortfolioDetailPage = router.pathname === "/portfolio/detail";
  const isLoginPage = router.pathname.startsWith("/login");
  const isProfilePage = router.pathname.startsWith("/profile");
  const isProfileAlarmPage = router.pathname.startsWith("/profile/alarm");
  const isWathlistPage = router.pathname === "/watchlist";
  const isWatchlistEditPage = router.pathname === "/watchlist/edit";
  const isTolerancePage = router.pathname.startsWith("/tolerance");
  const isLearnPage = router.pathname.startsWith("/learn");
  const isIndustryPage = router.pathname.startsWith("/industry");
  const isAlarmMethodPage = router.pathname === "/alarm-method";
  const isPortfolioContentsPage = router.pathname.startsWith(
    "/portfolio/detail/contents"
  );
  const isPortfolioRiskListPage = router.pathname.startsWith(
    "/portfolio/detail/risk-list"
  );
  const isPortfolioRiskComparePage = router.pathname.startsWith(
    "/portfolio/detail/risk-compare"
  );

  const isPortfolioSharpesPage = router.pathname.startsWith(
    "/portfolio/detail/sharpe"
  );

  const isPortfolioOptimizePage = router.pathname.startsWith(
    "/portfolio/detail/optimize"
  );
  const isB2bPage = router.pathname.startsWith("/b2b");

  return (
    <>
      {isMobile ? (
        <>
          {!isDetailPage && !isSignupPage && <MobileTopBar />}
          <div
            className={`${
              (router.pathname === "/portfolio" ||
                router.pathname === "/profile" ||
                (isDetailPage && router?.query?.type === "portfolio")) &&
              "mb-[104px]"
            } ${isSignupPage && "bg-white"} ${
              (router.pathname === "/" ||
                router.pathname === "/login" ||
                router.pathname === "/portfolio" ||
                router.pathname === "/profile" ||
                router.pathname === "/watchlist" ||
                router.pathname === "/learn") &&
              "mt-16 "
            } ${router.pathname === "/signup" && "mt-[0px]"} `}
          >
            {children}
          </div>
          {isSearchPage ||
          isSearchListPage ||
          isHighRiskPage ||
          isAssetPage ||
          isRealtimeRiskPage ||
          isPortfolioDetailPage ||
          isWathlistPage ||
          isIndustryPage ||
          isPortfolioRiskComparePage ||
          isPortfolioSharpesPage ||
          isCountryPage ||
          isPortfolioRiskListPage ||
          isPortfolioContentsPage ? (
            <NotiFooter isMobilePaddingAdd={isSearchPage || isWathlistPage} />
          ) : (
            (isLoginPage || isMainPage || isLearnPage) && (
              <div className="pr-20 mr-20 mt-10">
                <Footer />
              </div>
            )
          )}
          {!isDetailPage && <MobileFooter />}
          {isB2bPage && <B2bFooter />}
        </>
      ) : (
        <>
          <div className={` flex flex-col w-800:items-center relative`}>
            <TopBar />

            <div
              className={`${
                !isDetailPage
                  ? isSignupPage
                    ? "mt-[64px]"
                    : isLearnPage || isMainPage
                    ? "mt-16"
                    : "mt-20"
                  : "mt-16"
              } ${
                (router.pathname === "/" || router.pathname === "/profile") &&
                "mb-10"
              } ${
                (router.pathname === "/portfolio" ||
                  (isDetailPage && router?.query?.type === "portfolio")) &&
                "mb-[80px]"
              }`}
            >
              {children}
            </div>

            {isSearchPage ||
            isSearchListPage ||
            isHighRiskPage ||
            isAssetPage ||
            isRealtimeRiskPage ||
            isWathlistPage ||
            isPortfolioDetailPage ||
            isIndustryPage ||
            isPortfolioRiskComparePage ||
            isCountryPage ||
            isPortfolioSharpesPage ||
            isPortfolioRiskListPage ||
            isPortfolioContentsPage ? (
              <NotiFooter isDesktop={true} />
            ) : (
              !isPortfolioPage &&
              !isSignupPage &&
              !isWatchlistEditPage &&
              !isDetailPage &&
              !isProfileAlarmPage &&
              !isAlarmMethodPage &&
              !isB2bPage && (
                <div className="pr-20 mr-20 mt-10">
                  <Footer />
                </div>
              )
            )}
            {isB2bPage && <B2bFooter />}

            {!isDetailPage && (!isProfilePage || !isTolerancePage) && (
              <MenuBar />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
