// notificationsContext.tsx
import React, { createContext, useContext, ReactNode, useReducer } from "react";

type InterestToastStateType = {
  interestToastVisible: boolean;
  interestErrorToastVisible: boolean;
  existInterest: boolean;
  alarmModal?: boolean;
  ITEM_CD_DL?: string | string[] | undefined;
};

export type ActionType =
  | {
      type: "SET_INTEREST_TOAST_VISIBLE";
      payload: { toast: boolean; existInterest: boolean; alarmModal?: boolean };
    }
  | {
      type: "SET_INTEREST_ERROR_TOAST_VISIBLE";
      payload: { toast: boolean; existInterest: boolean };
    }
  | {
      type: "SET_ALARM_MODAL_VISIBLE";
      payload: {
        alarmModal: boolean;
        ITEM_CD_DL: string | string[] | undefined;
      };
    };

const InterestToastContext = createContext<InterestToastStateType | undefined>(
  undefined
);
const InterestToastDispatchContext = createContext<
  React.Dispatch<ActionType> | undefined
>(undefined);

const initialState: InterestToastStateType = {
  interestToastVisible: false,
  interestErrorToastVisible: false,
  existInterest: false,
  alarmModal: false,
  ITEM_CD_DL: undefined,
};

export const InterestToastProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [value, dispatch] = useReducer(
    (state: InterestToastStateType, action: ActionType) => {
      switch (action.type) {
        case "SET_INTEREST_TOAST_VISIBLE":
          return {
            ...state,
            interestToastVisible: action.payload.toast,
            existInterest: action.payload.existInterest,
            alarmModal: action.payload?.alarmModal || false,
          };
        case "SET_INTEREST_ERROR_TOAST_VISIBLE":
          return {
            ...state,
            interestErrorToastVisible: action.payload.toast,
            existInterest: action.payload.existInterest,
          };
        case "SET_ALARM_MODAL_VISIBLE":
          return {
            ...state,
            alarmModal: action.payload.alarmModal,
            ITEM_CD_DL: action.payload.ITEM_CD_DL,
          };
        default:
          return state;
      }
    },
    initialState
  );

  return (
    <InterestToastContext.Provider value={value}>
      <InterestToastDispatchContext.Provider value={dispatch}>
        {children}
      </InterestToastDispatchContext.Provider>
    </InterestToastContext.Provider>
  );
};

export function useInterestToastState() {
  const state = useContext(InterestToastContext);
  if (!state) throw new Error("Cannot find InterestToastProvider");
  return state;
}

export function useInterestToastDispatch() {
  const dispatch = useContext(InterestToastDispatchContext);
  if (!dispatch) throw new Error("Cannot find InterestToastProvider");
  return dispatch;
}
