import BottomModal from "components/common/Modal/BottomModal";
import Button from "components/common/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useModalClickOutside from "utils/useModalClickOutside";
import Lottie from "lottie-react";
import alarmAnimation from "../../../public/lottie/alarm.json";
import {
  useInterestToastDispatch,
  useInterestToastState,
} from "contexts/InterestContext";
import { useRouter } from "next/router";

function GlobalRiskAlarmNotiModal({}: any) {
  const dispatch = useInterestToastDispatch();
  const state = useInterestToastState();

  const modalClose = () => {
    dispatch({
      type: "SET_ALARM_MODAL_VISIBLE",
      payload: {
        alarmModal: false,
        ITEM_CD_DL: undefined,
      },
    });
  };

  const modalRef = useModalClickOutside(modalClose);
  //   const { t } = useTranslation("detail");

  const router = useRouter();

  const itemCD_DLs = state.ITEM_CD_DL;
  // console.log("itemCD_DLs", itemCD_DLs);
  let query = state.ITEM_CD_DL;
  if (Array.isArray(itemCD_DLs)) {
    query = itemCD_DLs.map((item: any) => item).join(",");
  }

  return (
    <div className={state.alarmModal ? "" : "hidden"}>
      <BottomModal
        isOpen={state.alarmModal}
        modalRef={modalRef}
        containerClass="gap-[24px] !z-[900]"
      >
        <div className="flex flex-col gap-2">
          <h1 className={`${TYPO.TYPO4_B} text-gray80 whitespace-pre-line`}>
            {router.locale === "ko"
              ? `관심있는 종목 리스크에 \n 변화가 생기면 알려드릴게요`
              : "Would you like to be notified if there are changes in the risk of your watchlist?"}
          </h1>
          <span className={`${TYPO.TYPO7_MD} text-gray50`}>
            {router.locale === "ko"
              ? "문자 또는 메일로 알림을 보내드려요"
              : "Via email or text message."}
          </span>
        </div>
        <div className="w-full items-center justify-center flex mb-[6px]">
          <Lottie
            animationData={alarmAnimation}
            renderer="svg"
            autoplay
            loop
            style={{ width: 120, height: 120 }}
          />
        </div>
        <div className="w-full flex gap-2 z-[100]">
          <Button
            type={"text_gray"}
            size={"xl"}
            text={router.locale === "ko" ? "다음에" : "Later"}
            width="grow-[0.5]"
            clickHandler={() => {
              const tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1);
              localStorage.setItem("hideModal", tomorrow.toISOString());
              // setModalOpen(false);
              modalClose();
            }}
          />
          <Button
            type={"primary"}
            size={"xl"}
            text={router.locale === "ko" ? "알림 받기" : "Notify Me"}
            width="grow-[0.5]"
            clickHandler={() => {
              // setAlarmRegister(true);
              // setModalOpen(false);
              modalClose();

              router.push(`/alarm-method?item=${query}`);
            }}
          />
        </div>
      </BottomModal>
    </div>
  );
}
export default GlobalRiskAlarmNotiModal;
